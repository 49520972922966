import { ThunkDispatch } from 'redux-thunk';
import { HistoricalResolution } from '@store/enums';
import {
    AggregatedDataAction,
    AggregatedDataUserState,
    AssetMonitoringHistoryResponse,
    EmobilityHistoryResponse,
    HistoricalAssetMonitoringData,
    HistoricalEmobilityData,
    RootState,
} from '@store/types';
import {
    clearDataBeforeRegistrationDate,
    setAggregatedDataError,
    splitInTwo,
} from '@store/actions';
import { GraphTypes } from '@tools/enums';
import { extractAggregatedData } from '@tools/utils';

type AppDispatch = ThunkDispatch<RootState, unknown, AggregatedDataAction>;

export const handleHistoricalEmobilityData = (
    date: Date,
    res: HistoricalResolution,
    error: boolean,
    dispatch: AppDispatch,
    data: EmobilityHistoryResponse[],
    userState: AggregatedDataUserState,
): HistoricalEmobilityData => {
    const { timezone, registrationDate } = userState;

    if (error) {
        dispatch(setAggregatedDataError(res, GraphTypes.EMOBILITY));
    }

    const [current, prev] = splitInTwo(
        clearDataBeforeRegistrationDate(data, registrationDate, res),
    );

    return {
        emobilityEnergy: extractAggregatedData(
            date,
            current,
            res,
            'energyConsumption',
            timezone,
        ),
        emobilityPower: extractAggregatedData(
            date,
            current,
            res,
            'powerConsumption',
            timezone,
        ),
        prevDay: {
            prevEmobilityEnergy: extractAggregatedData(
                date,
                prev,
                res,
                'energyConsumption',
                timezone,
            ),
            prevEmobilityPower: extractAggregatedData(
                date,
                prev,
                res,
                'powerConsumption',
                timezone,
            ),
        },
    };
};

export const handleHistoricalEmobilityAssetMonitoringData = (
    date: Date,
    res: HistoricalResolution,
    error: boolean,
    dispatch: AppDispatch,
    data: AssetMonitoringHistoryResponse[],
    userState: AggregatedDataUserState,
): HistoricalAssetMonitoringData => {
    const { timezone, registrationDate } = userState;

    if (error) {
        dispatch(
            setAggregatedDataError(res, GraphTypes.EMOBILITY_ASSET_MONITORING),
        );
    }

    const [current, prev] = splitInTwo(
        clearDataBeforeRegistrationDate(data, registrationDate, res),
    );

    return {
        assetMonitoringConsumption: extractAggregatedData(
            date,
            current,
            res,
            'consumption',
            timezone,
        ),
        assetMonitoringFromPV: extractAggregatedData(
            date,
            current,
            res,
            'fromPV',
            timezone,
        ),
        assetMonitoringFromGrid: extractAggregatedData(
            date,
            current,
            res,
            'fromGrid',
            timezone,
        ),
        assetMonitoringFromBattery: extractAggregatedData(
            date,
            current,
            res,
            'fromBattery',
            timezone,
        ),
        prevDay: {
            prevAssetMonitoringConsumption: extractAggregatedData(
                date,
                prev,
                res,
                'consumption',
                timezone,
            ),
            prevAssetMonitoringFromPV: extractAggregatedData(
                date,
                prev,
                res,
                'fromPV',
                timezone,
            ),
            prevAssetMonitoringFromGrid: extractAggregatedData(
                date,
                current,
                res,
                'fromGrid',
                timezone,
            ),
            prevAssetMonitoringFromBattery: extractAggregatedData(
                date,
                current,
                res,
                'fromBattery',
                timezone,
            ),
        },
    };
};
